import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/index.vue'
import Home from '../views/home/index.vue'
import Error from '../views/error/index.vue'
import { getMsg } from '../config/http'
import store from '../store/index';

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/error',
    name: 'Error',
    component: Error
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

router.beforeEach(async (to, from, next) => {
  const queryObj = JSON.parse(JSON.stringify(to.query))
  if (queryObj && queryObj.redirect) {
    sessionStorage.setItem('redirect', queryObj.redirect)
  }
  if (to.path == '/') {
    await new Promise((resolve, reject) => {
      getMsg(`${ process.env.VUE_APP_BASE__FONT_API }auth/server/tokens`,{ ticket: queryObj.ticket })
        .then((res) => {
          if (res.headers.redirect === 'true') {
            window.location.href = res.headers.location
            return
          }
          store.commit("setMenu",res.data.data);
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  next()
})

export default router
