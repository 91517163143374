<template>
  <!-- <div>错误页</div> -->
  <div class="dx-container">
    <span class="bk-font">登录遇到了问题...</span>
    <div>
      <p>
        &gt;
        <span>错误代码</span>: "
        <i>HTTP {{ code }} Not Found</i>"
      </p>
      <p>
        &gt;
        <span>错误描述</span>: "
        <i>{{ errorDesc }}</i>"
      </p>
      <p>
        &gt;
        <span>点击重新进入</span>: [
        <span class="login" @click="logIn">登录</span>
        ] <span>或{{countdown}}s后自动跳转</span>
      </p>
    </div>
  </div>
</template>
<script>
import { getMsg } from '@/config/http'

export default {
  name: 'Error',
  data() {
    return {
      code: null,
      errorDesc: '',
      countdown: 5,
      timer: null
    }
  },
  created() {
    this.getInfo()
  },
  mounted() {
    this.sendCode()
  },
  methods: {
    // 5s倒计时
    sendCode() {
      this.timer = setInterval(() => {
        if (this.countdown === 0) {
          this.clearTimer(this.timer)
          this.logIn()
        } else {
          this.countdown--
        }
      }, 1000)
    },
    clearTimer() {
      clearInterval(this.timer)
      this.timer = null
    },
    getInfo() {
      const params = new URLSearchParams(window.location.search)
      this.errorDesc = params.get('error')
      this.code = params.get('code')
    },
    logout() {
      getMsg(`${process.env.VUE_APP_BASE__LOGOUT_URL}authserver/logout`)
    },
    async logIn() {
      await this.logout()
      const urlPrefix = window.location.origin
      const url = `${urlPrefix}/authserver/login?service=${urlPrefix}${process.env.VUE_APP_BASE__FONT_API}auth/server/login`
      window.location.href = url
    }
  }
}
</script>
<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  color: inherit;
}
.dx-container {
  min-height: 100%;
  width: 100%;
  background-image: linear-gradient(120deg, #008fb9 0%, #000000 100%);
  background-size: 100% 100%;
  overflow: hidden;
}

.bk-font {
  font-size: 5vw;
  text-align: center;
  position: fixed;
  width: 100vw;
  z-index: 1;
  color: #ffffff26;
  text-shadow: 0 0 50px rgba(0, 0, 0, 0.07);
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: 'Montserrat', monospace;
}

div {
  background: rgba(0, 0, 0, 0);
  width: 70vw;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0 auto;
  padding: 30px 30px 10px;
  box-shadow: 0 0 150px -20px rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.login {
  color: #ffffff;
  cursor: pointer;
}

P {
  font-family: 'Share Tech Mono', monospace;
  color: #f5f5f5;
  margin: 0 0 20px;
  font-size: 17px;
  line-height: 1.2;
}

span {
  color: #f0c674;
}

i {
  color: #8abeb7;
}

div a {
  text-decoration: none;
}

b {
  color: #81a2be;
}

a.avatar {
  position: fixed;
  bottom: 15px;
  right: -100px;
  -webkit-animation: slide 0.5s 4.5s forwards;
  animation: slide 0.5s 4.5s forwards;
  display: block;
  z-index: 4;
}

a.avatar img {
  border-radius: 100%;
  width: 44px;
  border: 2px solid white;
}

@-webkit-keyframes slide {
  from {
    right: -100px;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    opacity: 0;
  }
  to {
    right: 15px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }
}
@keyframes slide {
  from {
    right: -100px;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    opacity: 0;
  }

  to {
    right: 15px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }
}
</style>
