import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store =new Vuex.Store({
  state: {
    // hasAuthForAdmin: false, // 右上角系统设置
    // hasAuthForApartment: false, //学生公寓门禁管理系统
    // hasAuthForBio: false, //生物特征基础数据库
    // hasAuthForSchoolyard: false, //校园智能门禁管理系统
    // hasAuthForThreeFactor: false //三因子核验系统
  },
  mutations: {
    setMenu(state,opt) {
      for (let [key, val] of Object.entries(opt)) {
        state[key] = val
      }
    }
  },
  actions: {},
  modules: {}
})

export default store