<template>
  <div class="page-home">
    <div class="container">
      <div class="header">
        <div class="header__left">
          <div class="logo"></div>
<!--         -->
        </div>
        <div class="header__right">
          <div class="operate-main">
            <div class="operate" @click="go(backstageData)" v-if="$store.state[backstageData.auth]">
              <img src="../../assets/image/setting.png" class="icon" />
              <span class="icon-font">设置</span>
            </div>
            <div class="operate" @click="logout">
              <img src="../../assets/image/logout.png" class="icon" />
              <span class="icon-font">退出</span>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="title">生物识别校园管理平台</div>
        <div class="title-2">Biometric campus management platform</div>
        <div class="btn-list">
          <div
            v-for="(item, index) in menuList"
            :key="index"
            class="btn-item"
            :class="[item.icon, {'btn-item--disabled': !$store.state[item.auth] }]"
            @click="go(item)"
          >
<!--            <div class="caption">{{ item.name }}</div>-->
          </div>
        </div>
      </div>
    </div>
    <div class="date-section">
      <span class="date">{{date}}</span>
      <span class="week">{{day}}</span>
      <span class="time">{{time}}</span>
    </div>
<!--    <div class="waves"></div>-->
  </div>
</template>

<script>
// import { init } from '@/assets/js/home'
import { getMsg } from '@/config/http'

export default {
  name: 'Home',
  data() {
    return {
      loginFormVisible: false,
      loginForm: {
        loginName: 'admin',
        password: ''
      },
      loginLoading: false,
      rules: {
        password: [{ required: true, message: '请输入密码' }]
      },
      backstageData: {
        name: '用户权限管理平台',
        auth: 'hasAuthForAdmin',
        tokenName: 'tokenForApartment',
        path: '/backstage'
      },
      menuList: [
        {
          name: '生物特征基础数据库',
          tokenName: 'tokenForBio',
          auth: 'hasAuthForBio',
          icon: 'icon-bio',
          path: '/swk'
        },
        {
          name: '校园智能门禁管理系统',
          auth: 'hasAuthForSchoolyard',
          tokenName: 'tokenForSchoolyard',
          icon: 'icon-gate',
          path: '/gate'
        },
        {
          name: '学生公寓门禁管理系统',
          auth: 'hasAuthForApartment',
          tokenName: 'tokenForApartment',
          icon: 'icon-face',
          path: '/face'
        },
        {
          name: '办公楼宇门禁管理系统',
          tokenName: 'tokenForOffice',
          auth: 'hasAuthForOffice',
          icon: 'icon-build',
          path: '/office'
        },
        {
          name: '教学楼宇门禁管理系统',
          tokenName: 'tokenForTeach',
          auth: 'hasAuthForTeach',
          icon: 'icon-room',
          path: '/teach'
        },
        {
          name: '实验室门禁管理系统',
          auth: 'hasAuthForLab',
          tokenName: 'tokenForLab',
          icon: 'icon-lab',
          path: '/lab'
        },
        {
          name: '三因子核验系统',
          auth: 'hasAuthForThreeFactor',
          tokenName: 'tokenForThreeFactor',
          icon: 'icon-person',
          path: '/newStudent'
        },
        // {
        //   name: '校园疫情防控系统',
        //   auth: 'hasAuthForHealth',
        //   tokenName: 'tokenForHealth',
        //   icon: 'icon-health',
        //   path: '/health'
        // },
        {
          name: '机房门禁系统',
          auth: 'hasAuthForEngine',
          tokenName: 'tokenForEngine',
          icon: 'icon-engine',
          path: '/engine'
        }
      ],
      day: '',
      date: '',
      time: '',
      interval: null
    }
  },
  mounted() {
    // init()
    this.interval = setInterval(()=> {
      this.updateDateTime();
    }, 1000);
  },
  methods: {
    updateDateTime() {
      let now = new Date();
      let days = ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
      let day = days[now.getDay()];

      let year = now.getFullYear();
      let month = String(now.getMonth() + 1).padStart(2, '0');
      let date = String(now.getDate()).padStart(2, '0');
      let hours = String(now.getHours()).padStart(2, '0');
      let minutes = String(now.getMinutes()).padStart(2, '0');
      let seconds = String(now.getSeconds()).padStart(2, '0');
      this.date = `${year}-${month}-${date}`
      this.day = `${day}`
      this.time = `${hours}:${minutes}:${seconds}`
    },
    go(item) {
      const ticket = this.$store.state.ticket
      const systemToken = this.$store.state[item.tokenName]
      const auth = this.$store.state[item.auth]
      const redirectUrl = sessionStorage.getItem('redirect')
      const url = redirectUrl && redirectUrl.indexOf(item.path) > -1 ? redirectUrl : item.path
      if (auth) {
        console.log(`${url}?ticket=${ticket}&systemToken=${systemToken}`)
        window.open(`${url}?ticket=${ticket}&systemToken=${systemToken}`)
      }
    },
    logout() {
      const logoutUrl = `${process.env.VUE_APP_BASE__LOGOUT_URL}authserver/logout`
      const tokenUrl = `${process.env.VUE_APP_BASE__FONT_API}auth/server/tokens`
      getMsg(logoutUrl).then(() => {
        let obj = {
          expired: true,
          ticket: this.$store.state.ticket
        }

        getMsg(tokenUrl, obj).then((res) => {
          if (res.headers.redirect === 'true') {
            window.location.href = res.headers.location
            return
          }
        })
      })
    }
  }
}
</script>
<style lang="less">
@import './index.less';
</style>
