import request  from './request'

export function postMsg(url, data, props = {}) {
  const config = {
    url: url,
    method: 'post',
    // data: Qs.stringify(data),
    data: translateData(data)
  }
  const configs = {
    ...config, ...props
  }
  return request(configs)
}

export function getMsg(url, params, props = {}) {
  const config = {
    url: url,
    method: 'get',
    params: { ...params }
  }
  const configs = {
    ...config, ...props
  }
  return request(configs)
}

export function translateData(data = {}) {
  const fd = new FormData()
  Object.keys(data).forEach((item) => {
    if (data[item] != null) {
      fd.append(item, data[item])
    }
  })
  return fd
}

