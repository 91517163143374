<template>
  <div class="login-container">
    <div class="waves"></div>
    <div class="login-panel">
      <div class="login-panel-right">
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">
          <div class="title-container">
            <span>统一登录入口</span>
          </div>
          <el-form-item prop="username">
            <el-input
                placeholder="请输入用户名"
                v-model="loginForm.username"
                name="username"
                type="text"
                auto-complete="on"
            />
          </el-form-item>
          <el-form-item prop="password">
            <el-input
                :type="pwdType"
                placeholder="请输入密码"
                v-model="loginForm.password"
                name="password"
                auto-complete="on"
                @keyup.enter.native="onLoginClicked" />
            <span class="show-pwd" @click="showPassword">
            </span>
          </el-form-item>
          <el-form-item>
            <el-button :loading="loading" type="primary" class="login-submit" @click.native.prevent="onLoginClicked">
              登 录
            </el-button>
            <div class="register"><div>还没有账号？</div><div class="link" @click="onRegisterClicked">立即注册</div></div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { init } from '@/assets/js/home'
import request from '@/config/request'

export default {
  name: 'Login',
  data() {
    const validatePassword = (rule, value, callback) => {
      if ((value === undefined) || (value === null)) {
        callback(new Error('密码不能为空'))
      } else if (value.length < 6) {
        callback(new Error('密码不能小于6位'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        username: 'test03',
        password: 'test0102'
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', message: '用户名不能为空' }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }]
      },
      loading: false,
      pwdType: 'password'
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  mounted() {
    init()
  },
  methods: {
    doLogin(username, password) {
      return request({
        url: '/auth/user/login',
        method: 'post',
        data: {
          username,
          password
        },
        headers: {
          'Content-Type': 'application/json'
        }
      })
    },
    doPostLogin(ticket) {
      return request({
        url: `${ process.env.VUE_APP_BASE__FONT_API }auth/server/login`,
        method: 'get',
        params: {
          ticket
        }
      })
    },
    showPassword() {
      if (this.pwdType === 'password') {
        this.pwdType = ''
      } else {
        this.pwdType = 'password'
      }
    },
    onLoginClicked() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.doLogin(this.loginForm.username, this.loginForm.password).then(res => {
            const ticket = res.data.value
            this.doPostLogin(ticket).then(res => {
              this.$router.push({ path: '/', query: { ticket }})
              this.loading = false;
            }).catch(() => {
              this.loading = false
            })
          }).catch(() => {
            this.loading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    onRegisterClicked() {
      this.$router.push({ path: '/register' })
    }
  }
}
</script>

<style lang="less">
.waves {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.login-container {
  position: fixed;
  display: flex;
  height: 100%;
  width: 100%;
  background: linear-gradient(151deg, rgba(59,79,86,1), rgba(45,48,49,1), rgba(38,41,42,1));
  align-items: center;
  justify-content: center;

  .login-panel {
    display: flex;
    align-items: center;

    .login-panel-right {
      display: inline-block;
      width: 600px;
      height: 524px;
      background: white;
      border-radius: 30px;
      padding: 24px 60px;

      .title-container {
        text-align: center;
        margin-top: 60px;
        margin-bottom: 100px;

        span {
          font-size: 36px;
          font-weight: bold;
        }
      }

      .show-pwd {
        position: absolute;
        right: 10px;
        display: none;
      }

      .login-submit {
        width: 100%;
        font-family: Alibaba-PuHuiTi;
        background-color: #8449F4;
        border-color: #8449F4;
        border-radius: 8px;
      }

      .register {
        font-size: 14px;
        display: inline-block;
        display: none;

        div {
          display: inline-block;
        }

        .link {
          color: #FE8C00;
          cursor: pointer;
        }
      }
    }
  }
}
</style>