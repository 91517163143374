import axios from 'axios'

const service = axios.create({
  timeout: 600000,
})

service.interceptors.request.use(config => {
  // 在发送请求之前做某事，比如说 设置token
  config.headers['token'] = 'token'
  config.baseURL =
      `${process.env.NODE_ENV === 'development' ? '/api' : ''}`
  return config
}, error => {
  console.log('error',error)
  // 请求错误时做些事
  return Promise.reject(error);
})

// 添加响应拦截器
service.interceptors.response.use(response => {
  return response;
}, error => {
  return Promise.reject(error) // 返回接口返回的错误信息
})

export default service